import { Box, ColorModeScript } from "@chakra-ui/react";
import React from "react";
import { PageContextProvider } from "src/hooks/usePageContext";
import "./style.css";
import type { PageContext } from "./types";
import { QueryClient, QueryClientProvider } from "react-query";
import { StoreProvider } from "store/store";
import { getTheme, MyChakraProvider } from "./theme";
import { TranslationProvider } from "./intl";

export function PageShell({
  children,
  pageContext,
  queryClient,
  createStore,
}: {
  children: React.ReactNode;
  pageContext: PageContext;
  queryClient: QueryClient;
  createStore: any;
}) {
  const theme = getTheme(pageContext);

  //TODO: remove this line when we have a dark mode
  theme.config.initialColorMode = "light";

  return (
    <>
      <style>
        {`
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        `}
      </style>
      <ColorModeScript
        type="cookie"
        initialColorMode={theme.config.initialColorMode}
      />
      <PageContextProvider pageContext={pageContext}>
        <StoreProvider createStore={createStore}>
          <TranslationProvider>
            <QueryClientProvider client={queryClient}>
              <MyChakraProvider theme={theme}>
                {/* <ColorModeToggle /> */}
                <Box animation="fadeIn 500ms" h="full">
                  {children}
                </Box>
              </MyChakraProvider>
            </QueryClientProvider>
          </TranslationProvider>
        </StoreProvider>
      </PageContextProvider>
    </>
  );
}
