import createContext from "zustand/context";
import { PageContext } from "src/renderer/types";
import create, { StoreApi } from "zustand";

export interface Configuration {
  publicKratosUrl: string;
  language: string;
}
export interface StoreState {
  configuration: Configuration;
}

const zustandContext = createContext<StoreApi<StoreState>>();
export const StoreProvider = zustandContext.Provider;
export const useStore = zustandContext.useStore;
export const useStoreApi = zustandContext.useStoreApi;

// call this on the server side before rendering the app
// pageContext.zustandInit is transfered to the browser in the initial html response
export const createStoreServer = (pageContext: PageContext) => {
  pageContext.zustandInit = {
    configuration: {
      publicKratosUrl: `${process.env.PUBLIC_URL}`,
      language: "hu",
    },
  } as Partial<StoreState>;
  return createStore({
    defaultState: pageContext.zustandInit,
  });
};

// call this on the browser side before rendering the app
// pageContext.zustandInit is transfered to the browser in the initial html response and contains the configuration
export const hydrateStore = (store, pageContext: PageContext) => {
  if (pageContext.zustandInit) {
    store.setState(pageContext.zustandInit);
  }
};

export interface CreateStoreOptions {
  defaultState?: Partial<StoreState>;
  url?: string;
}

// defaultState is passed on the server side to initialize the store with the configuration (environment variables)
// url is passed on the server side to initialize the store with the querystring from the request url
export const createStore = (options: CreateStoreOptions = {}) =>
  create<StoreState>()((set) => ({
    configuration: {
      publicKratosUrl: "",
      language: "",
    },
    // only passed on server-side render
    ...options.defaultState,
  }));
