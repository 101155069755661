import { createInstance } from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { useStore } from "store/store";
import hu from "../intl/hu";

export const initI18next = (lng: string) => {
  const instance = createInstance({
    resources: {
      // en: {
      //   translation: en,
      // },
      hu: {
        translation: hu,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  }).use(initReactI18next);
  instance.init({
    lng,
  });
  return instance;
};

export const TranslationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const language = useStore((store) => store.configuration.language);
  return (
    <I18nextProvider i18n={initI18next(language)}>{children}</I18nextProvider>
  );
};
