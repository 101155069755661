import { createRoot, hydrateRoot, Root } from "react-dom/client";
import { QueryClient } from "react-query";
import { createStore, hydrateStore } from "store/store";
import type { PageContextBuiltInClient } from "vike/client/router";
import { initI18next } from "./intl";
import { PageShell } from "./PageShell";
import type { PageContext } from "./types";

export const clientRouting = true;
export const hydrationCanBeAborted = true;
let root: Root | null = null;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

const store = createStore();

export async function onRenderClient(
  pageContext: PageContextBuiltInClient & PageContext
) {
  hydrateStore(store, pageContext);
  const _createStore = () => store;
  const { Page, pageProps } = pageContext;

  const page = (
      <PageShell
        pageContext={pageContext}
        queryClient={queryClient}
        createStore={_createStore}
      >
        <Page {...pageProps} />
      </PageShell>
  );
  const container = document.getElementById("page-view")!;
  if (container.innerHTML === "" || !pageContext.isHydration) {
    if (!root) {
      root = createRoot(container);
    }
    root.render(page);
    // SSR
  } else {
    root = hydrateRoot(container, page);
  }
}
