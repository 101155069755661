const locales = {
  signIn: "Bejelentkezés",
  forgotPassword: "Elfelejtett jelszó",
  "Sign in": "Bejelentkezés",
  Submit: "Elküld",
  Password: "Jelszó",
  recovery: "Jelszó visszaállítás",
  changePassword: "Jelszó megváltoztatása",
  Save: "Mentés",
  "New password": "Új jelszó",
  required: "Kötelező mező",
  "Sign in with Microsoft": "Bejelentkezés Microsoft fiókkal",
};

export default locales;
